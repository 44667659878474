import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Footer from '../components/footer';
import SocialShare from '../components/socialShare';
import landingPageBg from '../../static/landing-page-bg.jpg';


const Credits = ({ location, data }) => {
  const dataOptions = {
    url: location.href,
    title: `Credits - ${data.site.siteMetadata.title}`,
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <Layout classContainer="landing-page-bg" bgContainer={landingPageBg}>
      <SEO
        title={dataOptions.title}
        url={dataOptions.url}
      />
      <div id="credits-info-wrapper" className="main-fixed-wrapper">
        <Container fluid="lg">
          <Row className="mb-2 mb-md-5">
            <Col>
              <button
                type="button"
                onClick={goBack}
                className="back-link  mb-5 mb-md-0"
              >
                <span className="explore-arrow position-relative">
                  <span className="long-arrow left-arrow" />
                  <span className="label">Go Back</span>
                </span>
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="credits-heading reveal-animate mb-4 mb-md-5 text-center">Credits</h2>
            </Col>
          </Row>
          <Row className="reveal-animate justify-content-md-center">
            <Col lg={5} md={4}>
              <h4 className="text-center text-md-right mb-2 mx-md-3">Producers</h4>
            </Col>
            <Col md={6}>
              <ul className="text-center text-md-left">
                <li>Trish Dolman - Executive Producer</li>
                <li>Leena Minifie - Digital Producer</li>
                <li>Rudy Buttignol – Executive Producer, Knowledge Network</li>
                <li>Murray Battle – Executive Producer, Knowledge Network</li>
                <li>Erica Landrock – Supervising Producer, Knowledge Network</li>
              </ul>
            </Col>
          </Row>
          <Row className="reveal-animate justify-content-md-center">
            <Col lg={5} md={4}>
              <h4 className="text-center text-md-right mb-2 mx-md-3">Design & Development <br /> by Knowledge Network:</h4>
            </Col>
            <Col md={6}>
              <ul className="text-center text-md-left">
                <li>Ravi Singh - Director of Streaming Platforms</li>
                <li>Shiela Morfe - Lead Web Designer & Developer</li>
                <li>Madeline Adams - Graphic Designer</li>
                <li>David de Haas - Motion Graphics Designer</li>
              </ul>
            </Col>
          </Row>
          <Row className="reveal-animate justify-content-md-center">
            <Col lg={5} md={4}>
              <h4 className="text-center text-md-right mb-2 mx-md-3">API/Database Development <br /> by Affinity Bridge:</h4>
            </Col>
            <Col md={6}>
              <ul className="text-center text-md-left">
                <li>Ron Collins - Developer</li>
                <li>Floyd Mann - Lead Developer</li>
                <li>Dorota Mann - Project Manager</li>
                <li>Mack Hardy - Strategy Lead</li>
                <li>Robin Puga - Technical Lead</li>
              </ul>
            </Col>
          </Row>
          <Row className="reveal-animate justify-content-md-center">
            <Col lg={5} md={4}>
              <h4 className="text-center text-md-right mb-2 mx-md-3">Researchers:</h4>
            </Col>
            <Col md={6}>
              <ul className="text-center text-md-left">
                <li>Leah Siegel - Writer/Archival Researcher</li>
                <li>Ben Mussett - Writer/Archival Researcher</li>
                <li>Jennifer Chiu - Senior Researcher</li>
                <li>Lanna Lucas - Lead Archival Clearance</li>
                <li>Robin Folvik – Additional Archival research and clearance</li>
                <li>Thanks to Career Launcher</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <SocialShare dataOptions={dataOptions} />
      <Footer>
        <div id="footer-color" />
      </Footer>
    </Layout>
  );
}

export default Credits;

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`;



